<template>
  <LazyAtomsList
    v-if="parsedTags && parsedTags.length"
    :buttons="buttons"
    custom-class="tags"
    :items="parsedTags"
  >
    <slot />
  </LazyAtomsList>
</template>

<script lang="ts" setup>
import type { NavItem } from '~~/types'

const props = defineProps({
  tags: Array as () => string[],
  type: String,
  types: String,
  buttons: {
    type: Boolean,
    default: true,
  },
})

const route = useRoute()

const flattenTags = (tags: string[] | string[][]): string[] => [
  ...new Set(tags.flat(1).filter((tag) => !!tag)),
]

const parseTags = (tags: string[] | string[][]): NavItem[] =>
  tags.map((tag) => ({
    slug: tag,
    to: { id: props.types, query: { tag } }, // TODO: Needs to handle article + 's'?
    text: tag,
    options: { active: tag === route?.query?.tag },
  }))

const parsedTags = computed(() => [
  ...(props.tags && parseTags(flattenTags(props.tags))),
  { slug: 'close', to: { id: props.types }, icon: { name: 'x-mark' } },
])
</script>

<style lang="scss">
.tags-list {
  position: relative;
  display: flex;
  flex-flow: row wrap;

  .tags-item {
    &.active {
      color: var(--secondary);
    }
  }

  .tags-link {
    font-family: var(--font-emph);
    font-style: italic;
    font-weight: 400;
    font-size: 1rem;
    padding: czn.$rg czn.$md;
    align-items: center;
  }
}
</style>
